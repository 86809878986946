import React, {
    useState,
    useMemo,
    ReactElement,
} from 'react'

import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'
import useBeforeUnloadDialog from '@src/hooks/useBeforeUnloadDialog'

import FlowContext from './FlowContext'

interface Props {
    children: ReactElement,
    initStep: string,
    initData?: ISetupState,
}

function FlowContextProvider({
    children,
    initStep,
    initData,
}: Readonly<Props>) {
    const [
        currentStep,
        setCurrentStep,
    ] = useState<string>(initStep)
    const [
        state,
        setState,
    ] = useState<ISetupState>(initData || {})
    const [
        isEdited,
        setIsEdited,
    ] = useState<boolean>(false)

    const value = useMemo(() => {
        return {
            currentStep,
            setCurrentStep,
            state,
            setState,
            setIsEdited,
            isEdited,
        }
    }, [
        currentStep,
        state,
        isEdited,
    ])

    useBeforeUnloadDialog(isEdited)

    return (
        <FlowContext.Provider value={value}>
            {children}
        </FlowContext.Provider>
    )
}

export default FlowContextProvider
