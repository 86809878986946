import React, {
    useCallback, useEffect, useMemo,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import APP_URLS from '@src/constants/AppUrls'
import queryClient from '@src/services/queryClient'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

import Summary from './components/Summary'

function SummaryPage(): JSX.Element {
    const navigate = useNavigate()
    const {
        updateState,
        state,
        setIsEdited,
    } = useFlowRouter()

    const handleSubmit = useCallback(() => {
        updateState(() => {
            return {}
        })
        queryClient.resetQueries()
        navigate(APP_URLS.scanQR, {
            replace: true,
        })
    }, [
        navigate,
        updateState,
    ])

    const locationName = useMemo(() => {
        if (state.selectedLocation?.iataCode) {
            return `${state.selectedLocation.iataCode} ${state.selectedLocation.city}`
        }
        return state.selectedLocation?.locationName ?? ''
    }, [state.selectedLocation])

    useEffect(() => {
        setIsEdited(false)
    }, [setIsEdited])

    return (
        <Summary
            gateway={`${state.gatewayMac}`}
            location={locationName}
            area={`${state.selectedArea?.areaName}`}
            onSubmit={handleSubmit}
        />
    )
}

export default SummaryPage
