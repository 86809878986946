import React, {
    useCallback,
} from 'react'
import {
    useNavigate,
} from 'react-router-dom'
import AccessDenied from './components/AccessDenied'

function AccessDeniedPage(): JSX.Element {
    const navigate = useNavigate()
    const handleConfirm = useCallback(() => {
        navigate('/', {
            replace: true,
        })
    }, [navigate])

    return <AccessDenied onBackClick={handleConfirm} />
}

export default AccessDeniedPage
