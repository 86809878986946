import React, {
    useCallback, useMemo, useState,
} from 'react'

import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import macAddressIcon from '@src/assets/Mac_Address.svg'
import Header from '@src/Layout/components/Header'
import Button from '@src/shared-components/Button'
import MainPopup from '@src/MainPopup/MainPopup'
import Progress from '@src/shared-components/Progress'
import Layout from '@src/Layout'
import {
    getProgressOptions,
} from '@src/pages/ScanQR/components/ScanQR'
import openSupport from '@src/utils/jiraHelpDesk'
import consoleService from '@src/services/consoleService'
import Input from '@src/shared-components/Input/Input'
import ErrorWithSupportPage from '@src/pages/ScanQRError/ErrorWithSupportPage'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'
import useCheckGatewayId from '@src/api/hooks/useCheckGatewayId'

import {
    storeGateWayScannedData,
    storeError,
} from '@src/hooks/useGatewayScanned/gateWayScannedData.utils'
import {
    GatewayStatus,
} from '@src/types/enums'

import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import classes from './ManualMacAddress.style'

export type FormProps = {
    macAddress: string
}

function ManualMacAddress(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        updateState,
    } = useFlowRouter()
    const progressOptions = useMemo(() => {
        return getProgressOptions(t)
    }, [t])
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const [
        inputValue,
        setInputValue,
    ] = useState('')

    const handleOnSupportClick = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleManualInput = useCallback((_name: string, value: string) => {
        setInputValue(value)
    }, [])

    const isValid = inputValue.length > 0

    const onSuccess = useCallback((data: IGatewayByIdResponse) => {
        storeGateWayScannedData({
            updateState,
            gatewayByIdData: (data && data.gatewayStatus !== GatewayStatus.INSTALLED)
                ? data : undefined,
            gateway: inputValue,
        })
    }, [
        inputValue,
        updateState,
    ])
    const onError = useCallback((errorResponse: unknown) => {
        storeError({
            updateState,
            gatewayByIdError: errorResponse,
        })
    }, [updateState])

    const {
        mutate: checkGatewayId,
        reset,
        error,
        data: gatewayByIdData,
    } = useCheckGatewayId(onSuccess, onError)
    const validGateWayData = useMemo(() => {
        return gatewayByIdData && gatewayByIdData.gatewayStatus !== GatewayStatus.INSTALLED
    }, [gatewayByIdData])

    const handleNextClick = useCallback(() => {
        checkGatewayId({
            id: inputValue,
        })
    }, [
        checkGatewayId,
        inputValue,
    ])

    const onErrorBack = useCallback(() => {
        setInputValue('')
        reset()
    }, [reset])

    if (error || (gatewayByIdData && !validGateWayData)) {
        return (
            <ErrorWithSupportPage
                message={t('GATEWAY_NOT_FOUNT_ERROR')}
                header={t('GATEWAY_NOT_FOUNT_HEADER')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Layout
            header={(
                <Header
                    title={t('INSTALL_GATEWAY')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <div css={classes.input}>
                        <Input
                            name="macAddress"
                            value={inputValue}
                            label={t('MAC_ADDRESS_LABEL')}
                            onChange={handleManualInput}
                        />
                    </div>
                    <Button
                        variant="primary"
                        onClick={handleNextClick}
                        disabled={!isValid}
                    >
                        {t('NEXT')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={handleOnSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <div css={classes.root}>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <div css={classes.progress}>
                    <Progress
                        options={progressOptions}
                        currentStep={0}
                    />
                </div>
                <center css={classes.header}>
                    <Typography variant="headline5">
                        {t('MAC_ADDRESS_MANUALLY_HEADER')}
                    </Typography>
                </center>
                <div
                    css={classes.previewImg}
                    style={{
                        backgroundImage: `url(${macAddressIcon}`,
                    }}
                />
            </div>
        </Layout>
    )
}

export default ManualMacAddress
