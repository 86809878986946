import React from 'react'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import Button from '@src/shared-components/Button'
import useT from '@src/hooks/useT/useT'

import classes from './UnsavedChangesPrompt.style'

type Props = {
    open: boolean,
    handleClose: (value: boolean) =>void,
}

const UnsavedChangesPrompt = ({
    open,
    handleClose,
}: Props) => {
    const {
        t,
    } = useT()

    return (
        <Dialog
            open={open}
            onClose={() => { return handleClose(false) }}
            disableEscapeKeyDown
            onClick={(event) => { event.stopPropagation() }}
        >
            <DialogTitle>
                {t('UNSAVED_CHANGES_DIALOG_TITLE')}
            </DialogTitle>
            <DialogContent css={classes.dialogContent}>
                <DialogContentText>
                    {t('UNSAVED_CHANGES_DIALOG_TEXT')}
                </DialogContentText>
            </DialogContent>
            <DialogActions css={classes.dialogActions}>
                <div css={classes.button}>
                    <Button
                        variant="quaternary"
                        onClick={() => { handleClose(false) }}
                    >
                        {t('CANCEL')}
                    </Button>
                </div>
                <div css={classes.buttonPrimary}>
                    <Button
                        variant="quaternary"
                        onClick={() => { handleClose(true) }}
                    >
                        {t('DISCARD')}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default UnsavedChangesPrompt
