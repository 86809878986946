import React, {
    useCallback,
    useState,
    useEffect,
} from 'react'
import MapApiProvider from '@src/shared-components/MapApiProvider'
import useConfirmGatewayInstallation from '@src/hooks/useConfirnGatewayInstallation'
import Loading from '@src/shared-components/Loading'
import {
    checkLocationServicePermissions,
} from '@src/shared-components/MapApiProvider/components/utils'
import useT from '@src/hooks/useT/useT'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import ErrorPage from '../Error/ErrorPage'

import LocationConfirmationPage from './components/LocationConfirmationPage'
import ManualLocationConfirmationPage from './components/ManualLocationConfirmationPage'

function LocationConfirmation(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        goToStep,
    } = useFlowRouter()
    const onSuccess = useCallback(() => {
        goToStep(APP_FLOW_STEPS.summaryPage)
    }, [goToStep])
    const {
        isLoading,
        error,
        saveAndConfirm,
        resetErrors,
    } = useConfirmGatewayInstallation(onSuccess)
    const [
        isManualMode,
        setIsManualMode,
    ] = useState<boolean>(true)
    const [
        hasPermission,
        setHasPermission,
    ] = useState(false)

    const onConfirmClick = useCallback(() => {
        saveAndConfirm()
    }, [saveAndConfirm])

    const onAfterError = useCallback(() => {
        resetErrors()
        goToStep(APP_FLOW_STEPS.scanQR)
    }, [
        goToStep,
        resetErrors,
    ])

    const onManualClick = useCallback(() => {
        setIsManualMode(true)
    }, [])

    const onAutoClick = useCallback(() => {
        setIsManualMode(false)
    }, [])

    const haveLocationServicePermissionCallBack = useCallback(() => {
        setIsManualMode(false)
        setHasPermission(true)
    }, [
        setIsManualMode,
        setHasPermission,
    ])

    useEffect(() => {
        checkLocationServicePermissions(haveLocationServicePermissionCallBack)
    }, [haveLocationServicePermissionCallBack])

    if (error) {
        return (
            <ErrorPage
                onBackClick={onAfterError}
                backButtonTitle={t('TRY_AGAIN_BUTTON_TITLE')}
            />
        )
    }

    return (
        <MapApiProvider>
            <Loading isLoading={isLoading}>
                { !isManualMode && (
                    <LocationConfirmationPage
                        onConfirmClick={onConfirmClick}
                        onManualClick={onManualClick}
                    />
                )}
                { isManualMode && (
                    <ManualLocationConfirmationPage
                        onConfirmClick={onConfirmClick}
                        onAutoClick={onAutoClick}
                        noAutoLocation={!hasPermission}
                    />
                )}
            </Loading>
        </MapApiProvider>
    )
}

export default LocationConfirmation
