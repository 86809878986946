import React, {
    useCallback,
} from 'react'
import consoleService from '@src/services/consoleService'
import openSupport from '@src/utils/jiraHelpDesk'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import ScanQRError from './components/ScanQRError'

function ScanQRErrorPage(): JSX.Element {
    const {
        goToStep,
    } = useFlowRouter()
    const handleBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.scanQR)
    }, [goToStep])

    const handleEnterManuallyClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.manual_Mac_Address)
    }, [goToStep])

    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
    }, [jiraHelpDeskCallback])

    return (
        <ScanQRError
            onBackClick={handleBackClick}
            onEnterManuallyClick={handleEnterManuallyClick}
            onSupportClick={handleSupportClick}
        />
    )
}

export default ScanQRErrorPage
