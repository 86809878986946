import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'

import dictionary from '@src/dictionary'
import Loading from '@src/shared-components/Loading'
import useT from '@src/hooks/useT/useT'
import useCheckAreaAvailability from '@src/api/hooks/useCheckAreaAvailability'
import useGetLocationArea, {
    ILocationAreaModel,
} from '@src/api/hooks/useGetLocationArea'
import useGetLocations from '@src/api/hooks/useGetLocations'
import {
    ILocationModel,
} from '@src/api/hooks/useGetGatewayById'
import useHasPermission, {
    AUTH_ROLES,
} from '@src/api/hooks/useHasPermission'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'

import ErrorWithSupportPage from '../ScanQRError/ErrorWithSupportPage'
import GatewayDetected from './components/GatewayDetected'

function GatewayDetectedPage(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        goToStep,
        state: storedState,
        updateState,
        setIsEdited,
    } = useFlowRouter()
    const {
        data: areaData,
        isLoading: areaIsLoading,
        error: areaError,
    } = useGetLocationArea(storedState.selectedLocation?.id ?? 0, {
        enabled: !!storedState.selectedLocation,
    })

    const handleOnAreaAvailabilityCheckSuccess = useCallback(() => {
        goToStep(APP_FLOW_STEPS.installGateway)
    }, [goToStep])

    const {
        mutate: checkAreaAvailabilityMutate,
        isPending: confirmcheckAreaAvailabilityPending,
        error: checkAreaAvailabilityError,
        reset: resetcheckAreaAvailabilityError,
    } = useCheckAreaAvailability(handleOnAreaAvailabilityCheckSuccess)

    const hasAdvancedPermission = useHasPermission(AUTH_ROLES.SGA_USER_ADVANCED)
    const [
        locationSearchValue,
        setLocationSearchValue,
    ] = useState<string | undefined>(undefined)

    const {
        data: locationsData,
        isLoading: locationsIsLoading,
    } = useGetLocations({
        enabled: hasAdvancedPermission,
        searchAll: locationSearchValue,
    })

    const isLoading = useMemo(() => {
        return areaIsLoading || confirmcheckAreaAvailabilityPending
    }, [
        areaIsLoading,
        confirmcheckAreaAvailabilityPending,
    ])

    const error = useMemo(() => {
        return areaError || checkAreaAvailabilityError
    }, [
        areaError,
        checkAreaAvailabilityError,
    ])

    const initOptions = useMemo(() => {
        return storedState.gatewayInfo?.location
            ? dictionary.formatLocationsOptions([storedState.gatewayInfo?.location])
            : []
    }, [storedState.gatewayInfo?.location])

    const locationOptions = useMemo(() => {
        if (hasAdvancedPermission) {
            return locationSearchValue === undefined
                ? initOptions
                : dictionary.formatLocationsOptions(locationsData)
        }
        return dictionary.formatLocationOptions(storedState.gatewayInfo?.location)
    }, [
        hasAdvancedPermission,
        initOptions,
        locationSearchValue,
        locationsData,
        storedState.gatewayInfo?.location,
    ])

    const areaOptions = dictionary
        .useGetLocationAreaOptions(storedState.selectedLocation?.id ? areaData : [])

    const handleNextClick = useCallback(() => {
        checkAreaAvailabilityMutate({
            areaId: storedState.selectedArea?.id ?? 0,
        })
    }, [
        checkAreaAvailabilityMutate,
        storedState.selectedArea,
    ])

    const doBackClick = useCallback(() => {
        resetcheckAreaAvailabilityError()
        updateState((prev) => {
            return {
                ...prev,
                gatewayMac: undefined,
                gatewayInfo: undefined,
                selectedLocation: undefined,
                selectedArea: undefined,
            }
        }, APP_FLOW_STEPS.scanQR)
    }, [
        updateState,
        resetcheckAreaAvailabilityError,
    ])

    const onErrorBack = useCallback(() => {
        resetcheckAreaAvailabilityError()
    }, [resetcheckAreaAvailabilityError])

    const handleAreaChange = useCallback(
        (data?: ILocationAreaModel) => {
            updateState((prev) => {
                return {
                    ...prev, selectedArea: data,
                }
            })
        },
        [updateState],
    )

    const handleAirportChange = useCallback(
        (data?: ILocationModel) => {
            updateState((prev) => {
                return {
                    ...prev, selectedLocation: data,
                }
            })
        },
        [updateState],
    )

    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false)

    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            doBackClick()
        } else { setShowModal(false) }
    }, [doBackClick])

    const handleBackClick = useCallback(() => {
        setShowModal(true)
    }, [])

    useEffect(() => {
        setIsEdited(true)
    }, [setIsEdited])

    if (error) {
        return (
            <ErrorWithSupportPage
                message={t('AREA_NOT_AVAILABILITY_MESSAGE')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Loading isLoading={isLoading}>
            { !locationsIsLoading && (
                <>
                    <GatewayDetected
                        selectedLocationId={storedState.selectedLocation?.id?.toString() ?? ''}
                        selectedAreaId={storedState.selectedArea?.id?.toString() ?? ''}
                        onAirportChange={handleAirportChange}
                        onAreaChange={handleAreaChange}
                        locationOptions={locationOptions}
                        areaOptions={areaOptions}
                        gatewayNumber={storedState?.gatewayMac}
                        onNextClick={handleNextClick}
                        onBackClick={handleBackClick}
                        locationEnabled={hasAdvancedPermission}
                        setLocationSearchValue={setLocationSearchValue}
                    />
                    <UnsavedChangesPrompt
                        open={showModal}
                        handleClose={handleModalClose}
                    />
                </>
            )}
        </Loading>
    )
}

export default GatewayDetectedPage
