import React, {
    useCallback,
} from 'react'

import Typography from '@src/shared-components/Typography'
import useT from '@src/hooks/useT/useT'
import gatewayInstalledIcon from '@src/assets/install_gateway_Illustration.svg'
import InstallGatewayLayout from '@src/shared-components/InstallGatewayLayout'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import classes from './InstallGateway.style'

function InstallGateway(): JSX.Element {
    const {
        t,
    } = useT()
    const {
        goToStep,
    } = useFlowRouter()

    const handleNextClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.takePhotoOverviewEmpty)
    }, [goToStep])

    const onBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.gatewayDetected)
    }, [goToStep])

    return (
        <InstallGatewayLayout
            onBackClick={onBackClick}
            onNextClick={handleNextClick}
            nextButtonText={t('CONFIRM')}
        >
            <center css={classes.header}>
                <Typography variant="headline5">
                    {t('CHECK_GATEWAY_INSTALLED')}
                </Typography>
            </center>
            <div
                css={classes.previewImg}
                style={{
                    backgroundImage: `url(${gatewayInstalledIcon}`,
                }}
            />
        </InstallGatewayLayout>
    )
}

export default InstallGateway
