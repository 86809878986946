import React, {
    useCallback, useMemo, useState,
} from 'react'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'
import TakePhoto from './components/TakePhoto'

function TakePhotoPage(): JSX.Element {
    const {
        addPhoto,
        photos,
        cleanUp: cleanUpSavedPhotos,
    } = usePhotoContext()
    const {
        goToStep,
    } = useFlowRouter()
    const doBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.takePhotoOverviewEmpty)
    }, [goToStep])

    const handleTakeClick = useCallback(
        (e: { url: string }) => {
            addPhoto(e.url)
        },
        [addPhoto],
    )

    const handleProceedClick = useCallback(() => {
        return goToStep(APP_FLOW_STEPS.takePhotoOverview)
    }, [goToStep])

    const anyPhotos = useMemo(() => {
        return photos.length >= 1
    }, [photos])

    const [
        showModal,
        setShowModal,
    ] = useState<boolean>(false)

    const handleBackClick = useCallback(() => {
        if (anyPhotos) {
            setShowModal(true)
        } else {
            doBackClick()
        }
    }, [
        anyPhotos,
        doBackClick,
    ])

    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            cleanUpSavedPhotos()
            doBackClick()
        } else {
            setShowModal(false)
        }
    }, [
        cleanUpSavedPhotos,
        doBackClick,
    ])

    return (
        <>
            <TakePhoto
                onTakeClick={handleTakeClick}
                onBackClick={handleBackClick}
                onProceedClick={handleProceedClick}
            />
            <UnsavedChangesPrompt
                open={showModal}
                handleClose={handleModalClose}
            />
        </>
    )
}

export default TakePhotoPage
