import {
    css,
} from '@emotion/react'

const classes = {
    dialogActions: css`
      justifyContent: 'center',
    `,
    button: css`
        width: auto;
          & button div {
            color: #00739A;
            border-radius: 4px;
          };
    `,
    buttonPrimary: css`
      width: auto;
          & button div {
            color: #B00020;
            border-radius: 4px;
          };
    `,
    dialogContent: css`
        justifyContent: 'center',
    `,
}

export default classes
