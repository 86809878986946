import {
    useCallback,
    useContext,
} from 'react'
import FlowContext from '@src/shared-components/FlowRouter/FlowContext'
import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'

function useFlowRouter() {
    const context = useContext(FlowContext)

    const goToStep = useCallback((path: string) => {
        context.setCurrentStep(path)
    }, [context])

    const updateState = useCallback((
        callback: (prev: ISetupState) => ISetupState,
        toStep?: string,
    ) => {
        context.setState(callback)
        if (toStep) {
            goToStep(toStep)
        }
    }, [
        context,
        goToStep,
    ])

    return {
        currentStep: context.currentStep,
        goToStep,
        state: context.state,
        updateState,
        setIsEdited: context.setIsEdited,
        isEdited: context.isEdited,
    }
}

export default useFlowRouter
