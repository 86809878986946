import React, {
    useCallback,
    useState,
} from 'react'

import {
    useNavigate,
} from 'react-router-dom'
import APP_URLS from '@src/constants/AppUrls'
import consoleService from '@src/services/consoleService'
import openSupport
    from '@src/utils/jiraHelpDesk'
import queryClient
    from '@src/services/queryClient'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import useAuthContext from '@src/hooks/useAuthContext'
import UnsavedChangesPrompt from '@src/shared-components/UnsavedChangesPrompt'
import useTimeout from '@src/hooks/useTimeout/useTimeout'
import usePhotoContext from '@src/context/PhotosContext/usePhotoContext'

import MainPopup from './components/MainPopup'

enum ActionType {
    Logout = 'logout',
    Install = 'install',
}

type Props = {
    isOpen: boolean
    onClose?: () => void
}

function MainPopupContainer({
    isOpen, onClose,
}: Props): JSX.Element {
    const {
        updateState,
        isEdited,
        setIsEdited,
    } = useFlowRouter()
    const [
        showModalFor,
        setShowModalFor,
    ] = useState<{
        show: boolean,
        for?: ActionType,
    }>({
        show: false,
    })
    const {
        cleanUp: cleanUpSavedPhotos,
    } = usePhotoContext()

    const hideModal = useCallback(() => {
        setShowModalFor({
            show: false,
        })
    }, [setShowModalFor])

    const {
        timeout,
    } = useTimeout()
    const navigate = useNavigate()
    const {
        userInfo, logout,
    } = useAuthContext()

    const doInstall = useCallback(() => {
        updateState(() => {
            return {}
        })
        queryClient.resetQueries()
        navigate(APP_URLS.scanQR, {
            replace: true,
        })
        if (onClose) {
            onClose()
        }
    }, [
        navigate,
        onClose,
        updateState,
    ])
    const handleInstallClick = useCallback(() => {
        if (isEdited) {
            setShowModalFor({
                show: true,
                for: ActionType.Install,
            })
        } else {
            hideModal()
            doInstall()
        }
    }, [
        doInstall,
        hideModal,
        isEdited,
    ])

    const jiraHelpDeskCallback = useCallback(() => {
        return openSupport((): void => {
            consoleService.error('Jira extension failed to load.')
        })
    }, [])

    const handleSupportClick = useCallback(() => {
        jiraHelpDeskCallback()
        if (onClose) {
            onClose()
        }
    }, [
        jiraHelpDeskCallback,
        onClose,
    ])

    const doLogout = useCallback(() => {
        logout()
        if (onClose) {
            onClose()
        }
    }, [
        logout,
        onClose,
    ])

    const handleLogoutClick = useCallback(() => {
        if (isEdited) {
            setShowModalFor({
                show: true,
                for: ActionType.Logout,
            })
        } else {
            hideModal()
            doLogout()
        }
    }, [
        isEdited,
        hideModal,
        doLogout,
    ])
    const handleModalClose = useCallback((positive: boolean) => {
        if (positive) {
            cleanUpSavedPhotos()
            setIsEdited(false)
            if (showModalFor.for === ActionType.Logout) {
                timeout(logout)
            } else {
                doInstall()
            }
        } else {
            hideModal()
        }
    }, [
        setIsEdited,
        showModalFor.for,
        timeout,
        logout,
        doInstall,
        hideModal,
        cleanUpSavedPhotos,
    ])

    return (
        <>
            <MainPopup
                userInfo={userInfo}
                isOpen={isOpen}
                onClose={onClose}
                onInstallClick={handleInstallClick}
                onSupportClick={handleSupportClick}
                onLogoutClick={handleLogoutClick}
            />
            <UnsavedChangesPrompt
                open={showModalFor.show}
                handleClose={handleModalClose}
            />
        </>
    )
}

export default MainPopupContainer
