import React, {
    useMemo,
} from 'react'
import {
    Navigate,
    Route,
    Routes,
} from 'react-router-dom'
import TermsPage from '../../pages/Terms'
import MainPage from '../../pages/Main'
import APP_URLS from '../../constants/AppUrls'
import GatewayReceivedByUrl from '../../pages/GatewayDetected/GatewayRecievedByUrl'
import RedirectPage from './RedirectPage'

type CustomRoute = {
    path: string;
    element: JSX.Element,
    requireAcceptedTerms?: boolean
}

const getRoutes = (hasAcceptedTerms:boolean | undefined): CustomRoute[] => {
    return [
        {
            path: APP_URLS.terms,
            // if user on terms page but already confirmed terms - redirect to ScanQRPage
            element: hasAcceptedTerms ? (
                <Navigate
                    to={APP_URLS.scanQR}
                    replace
                />
            )
                : <TermsPage />,
        },
        {
            path: APP_URLS.scanQR,
            element: <MainPage />,
            requireAcceptedTerms: true,
        },
        {
            path: '/',
            element: hasAcceptedTerms ? (
                <Navigate
                    to={APP_URLS.scanQR}
                    replace
                />
            )
                : (
                    <Navigate
                        to={APP_URLS.terms}
                        replace
                    />
                ),
        },
        {
            path: APP_URLS.gatewayDetectedWithGateway,
            element: <GatewayReceivedByUrl />,
            requireAcceptedTerms: true,
        },
    ]
}

type Props = {
    hasAcceptedTerms: boolean | undefined
}
function RenderRoutes({
    hasAcceptedTerms,
} : Readonly<Props>) {
    const routes = useMemo(() => {
        return getRoutes(hasAcceptedTerms)
    }, [hasAcceptedTerms])

    const renderProtectedRoute = (route: CustomRoute): JSX.Element => {
        const Element = route.element

        if (!hasAcceptedTerms && route.requireAcceptedTerms) {
            // If route requires accepted terms - then redirect to TermsPage
            return <TermsPage />
        }

        return Element
    }

    return (
        <Routes>
            <Route
                path="redirect"
                element={<RedirectPage />}
            />
            {routes.map((route) => {
                return (
                    <Route
                        key={route.path}
                        element={renderProtectedRoute(route)}
                        path={route.path}
                    />
                )
            })}
            <Route
                path="*"
                element={<MainPage />}
            />
        </Routes>
    )
}

export default RenderRoutes
