import {
    createContext,
} from 'react'
import noop from 'lodash/noop'

import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'

export type FlowContextType = {
    currentStep: string,
    setCurrentStep: (value: string) => void,
    state: ISetupState,
    setState: (callback: (prev: ISetupState) => ISetupState,) => void,
    setIsEdited: (value: boolean) => void,
    isEdited: boolean,
}

const FlowContext = createContext<FlowContextType>({
    currentStep: '',
    setCurrentStep: noop,
    state: {} as ISetupState,
    setState: noop,
    setIsEdited: noop,
    isEdited: false,
})

export default FlowContext
