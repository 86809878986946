import React, {
    useCallback,
    useMemo,
    useEffect,
} from 'react'
import {
    useParams,
    useNavigate,
} from 'react-router-dom'

import Loading from '@src/shared-components/Loading'
import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'
import useGetGatewayById from '@src/api/hooks/useGetGatewayById'
import ErrorWithSupportPage from '@src/pages/ScanQRError/ErrorWithSupportPage'
import useT from '@src/hooks/useT/useT'
import {
    GatewayStatus,
} from '@src/types/enums'
import APP_URLS from '@src/constants/AppUrls'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

const GatewayReceivedByUrl = () => {
    const {
        gateway = '',
    } = useParams()
    const navigate = useNavigate()
    const {
        updateState,
    } = useFlowRouter()
    const {
        t,
    } = useT()

    const {
        data: gatewayByIdData,
        isLoading: gatewayByLoading,
        error: gatewayByIdError,
    } = useGetGatewayById(gateway, {
        enabled: !!gateway,
        retry: false,
    })

    const validGateway = useMemo(() => {
        return gatewayByIdData && gatewayByIdData.gatewayStatus !== GatewayStatus.INSTALLED
    }, [gatewayByIdData])

    useEffect(() => {
        if (gatewayByIdData && validGateway) {
            navigate(APP_URLS.scanQR, {
                state: {
                    step: APP_FLOW_STEPS.gatewayDetected,
                    info: {
                        gatewayMac: gateway,
                        gatewayInfo: gatewayByIdData,
                        selectedLocation: gatewayByIdData?.location,
                    },
                },
                replace: true,
            })
        } else
            if (gatewayByIdError) {
                updateState(
                    (prev: ISetupState) => {
                        return {
                            ...prev,
                            gatewayMac: undefined,
                        }
                    },
                )
                navigate(APP_URLS.scanQR, {
                    state: {
                        step: APP_FLOW_STEPS.scanQRError,
                    },
                    replace: true,
                })
            }
    }, [
        gateway,
        gatewayByIdData,
        gatewayByIdError,
        navigate,
        updateState,
        validGateway,
    ])

    const onErrorBack = useCallback(() => {
        navigate(APP_URLS.scanQR, {
            replace: true,
        })
    }, [navigate])

    if (gatewayByIdData && !validGateway) {
        return (
            <ErrorWithSupportPage
                message={t('GATEWAY_NOT_FOUNT_ERROR')}
                header={t('GATEWAY_NOT_FOUNT_HEADER')}
                onBackClick={onErrorBack}
            />
        )
    }

    return (
        <Loading isLoading={gatewayByLoading} />
    )
}

export default GatewayReceivedByUrl
