const APP_FLOW_STEPS = {
    scanQR: '/scan-qr', // first page of flow - 1 page of flow - entry point
    manual_Mac_Address: '/manual-mac-address', // 1.1 page of flow
    gatewayDetected: '/gateway-detected', // 2 page of flow
    installGateway: '/install-gateway', // 3 page of flow
    takePhotoOverviewEmpty: '/take-photo-overview-empty', // 4 page of flow
    takePhoto: '/take-photo', // 5 page of flow
    takePhotoOverview: '/take-photo-overview', // 6 page of flow
    locationConfirm: '/location-confirm', // 7 page of flow
    summaryPage: '/summary', // 8 page of flow
    scanQRError: '/scan-qr-error', // shown when error occurs from gatewayDetectedWithGateway - leave it in route for now
}

export default APP_FLOW_STEPS
