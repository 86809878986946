import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import {
    IGatewayByIdResponse,
} from '@src/api/hooks/useGetGatewayById'
import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'

export const storeGateWayScannedData = ({
    updateState,
    gateway,
    gatewayByIdData,
}: {
        updateState: (callback: (prev: ISetupState) => ISetupState, toStep?: string) => void,
        gateway?: string,
        gatewayByIdData?: IGatewayByIdResponse,
}) => {
    if (gatewayByIdData) {
        updateState((prev: ISetupState) => {
            return {
                ...prev,
                gatewayMac: gateway,
                gatewayInfo: gatewayByIdData,
                selectedLocation: gatewayByIdData?.location,
            }
        }, APP_FLOW_STEPS.gatewayDetected)
    }
}

export const storeError = ({
    updateState,
    gatewayByIdError,
    goToStep,
}: {
    updateState: (callback: (prev: ISetupState) => ISetupState, toStep?: string) => void,
    gatewayByIdError?: unknown,
    goToStep?: boolean,
}) => {
    if (gatewayByIdError) {
        updateState((prev: ISetupState) => {
            return {
                ...prev,
                gatewayMac: undefined,
            }
        }, goToStep ? APP_FLOW_STEPS.scanQRError : undefined)
    }
}
