import React, {
    ReactNode,
    useMemo,
} from 'react'
import parseErrorMessage, {
    IError,
} from '@src/utils/parseErrorMessage'
import Error from './components/Error'

type Props = {
    error?: unknown
    onBackClick?: () => void,
    backButtonTitle?: ReactNode
}
function ErrorPage({
    error, onBackClick, backButtonTitle,
}: Props): JSX.Element {
    const message = useMemo(() => {
        return error ? parseErrorMessage(error as IError) : undefined
    }, [error])

    return (
        <Error
            onBackClick={onBackClick}
            message={message}
            backButtonTitle={backButtonTitle}
        />
    )
}

export default ErrorPage
