import React from 'react'
import Popup from '@src/shared-components/Popup'
import Button from '@src/shared-components/Button'
import Typography from '@src/shared-components/Typography'
import {
    UserInfo,
} from '@src/context/AuthContext'
import useT from '@src/hooks/useT/useT'
import classes from './MainPopup.style'
import ChangeLanguageMenu from './ChangeLanguageMenu'

type Props = {
    isOpen: boolean
    userInfo: UserInfo
    onClose?: () => void
    onInstallClick: () => void
    onSupportClick: () => void
    onLogoutClick: () => void
}

function MainPopup({
    isOpen, userInfo, onClose, onInstallClick, onSupportClick, onLogoutClick,
}: Props): JSX.Element {
    const {
        t,
    } = useT()

    return (
        <Popup
            css={classes.popup}
            isOpen={isOpen}
            onClose={onClose}
        >
            <div css={classes.root}>
                <div css={classes.content}>
                    <Typography
                        variant="subtitle1"
                        css={{
                            paddingBottom: '8px',
                        }}
                    >
                        {userInfo.email}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        css={{
                            paddingBottom: '44px',
                        }}
                    >
                        {userInfo.name}
                    </Typography>
                    <div css={classes.hr} />
                    <Button
                        variant="quaternary"
                        onClick={onInstallClick}
                    >
                        {t('INSTALL_GATEWAY')}
                    </Button>
                    <div css={classes.hr} />
                    <ChangeLanguageMenu onClose={onClose} />
                    <div css={classes.hr} />
                    <Button
                        variant="quaternary"
                        onClick={onSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                    <div css={classes.hr} />
                </div>

                <Button
                    variant="secondary-outlined"
                    onClick={onLogoutClick}
                >
                    {t('LOGOUT')}
                </Button>
            </div>
        </Popup>
    )
}

export default MainPopup
