import React, {
    useCallback,
    useMemo, useState,
} from 'react'

import Button from '@src/shared-components/Button'
import Layout from '@src/Layout'
import Header from '@src/Layout/components/Header'
import MainPopup from '@src/MainPopup'
import Progress from '@src/shared-components/Progress'
import {
    IProgressOption,
} from '@src/shared-components/Progress/Progress'
import QrCodeScanner from '@src/shared-components/QrCodeScanner'
import Typography from '@src/shared-components/Typography'
import useT, {
    TFn,
} from '@src/hooks/useT/useT'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'

import classes from './ScanQR.style'

type Props = {
    onSupportClick: () => void
    onScanSuccess: (value: string) => void,
}

export const getProgressOptions = (t: TFn): IProgressOption[] => {
    return [
        {
            label: t('IDENTIFICATION'),
        },
        {
            label: t('INSTALLATION'),
        },
        {
            label: t('CONNECTION'),
        },
    ]
}
function ScanQR({
    onSupportClick, onScanSuccess,
}: Props): JSX.Element {
    const [
        isPopupOpened,
        showMainPopup,
    ] = useState(false)
    const {
        goToStep,
    } = useFlowRouter()
    const {
        t,
    } = useT()
    const progressOptions = useMemo(() => {
        return getProgressOptions(t)
    }, [t])
    const handleManualClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.manual_Mac_Address)
    }, [goToStep])

    return (
        <Layout
            header={(
                <Header
                    title={t('INSTALL_GATEWAY')}
                    onMenuClick={() => {
                        return showMainPopup((open) => {
                            return !open
                        })
                    }}
                />
            )}
            footer={(
                <>
                    <Button
                        variant="primary"
                        onClick={handleManualClick}
                    >
                        {t('MAC_ADDRESS_MANUALLY_BUTTON')}
                    </Button>
                    <Button
                        variant="primary-outlined"
                        onClick={onSupportClick}
                    >
                        {t('SUPPORT')}
                    </Button>
                </>
            )}
        >
            <div css={classes.root}>
                <MainPopup
                    isOpen={isPopupOpened}
                    onClose={() => {
                        return showMainPopup(false)
                    }}
                />
                <div css={classes.progress}>
                    <Progress
                        options={progressOptions}
                        currentStep={0}
                    />
                </div>
                <Typography
                    variant="headline5"
                    css={{
                        textAlign: 'center',
                        paddingTop: '24px',
                        paddingBottom: '24px',
                    }}
                >
                    {t('SCAN_GATEWAY_QR')}
                </Typography>
                <QrCodeScanner onScan={onScanSuccess} />
            </div>
        </Layout>
    )
}

export default ScanQR
