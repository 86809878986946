import React from 'react'
import {
    useLocation,
} from 'react-router-dom'

import FlowRouter from '@src/shared-components/FlowRouter'
import FlowContextProvider from '@src/shared-components/FlowRouter/FlowContextProvider'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import {
    ISetupState,
} from '@src/shared-components/FlowRouter/Flow.types'

function MainPage(): JSX.Element {
    const {
        state,
    } = useLocation()

    const initStep = state?.step || APP_FLOW_STEPS.scanQR
    const initData = state?.info as ISetupState

    return (
        <FlowContextProvider
            initStep={initStep}
            initData={initData}
        >
            <FlowRouter />
        </FlowContextProvider>
    )
}

export default MainPage
