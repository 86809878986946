import React, {
    useCallback,
} from 'react'
import gatewayInstalledIcon from '@src/assets/camera.svg'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'

import classes from './TakePhotoOverviewEmpty.style'
import TakePhotoOverviewPage from './components/TakePhotoOverviewPage'

function TakePhotoOverviewEmpty(): JSX.Element {
    const {
        goToStep,
    } = useFlowRouter()
    const handleBackClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.installGateway)
    }, [goToStep])

    const handleNextClick = useCallback(() => {
        goToStep(APP_FLOW_STEPS.takePhoto)
    }, [goToStep])

    return (
        <TakePhotoOverviewPage
            onBackClick={handleBackClick}
            onTakePhotoClick={handleNextClick}
            allowedTakePhoto
            takePhotoPrimary
        >
            <div
                css={classes.previewImgEmpty}
                style={{
                    backgroundImage: `url(${gatewayInstalledIcon}`,
                }}
            />
        </TakePhotoOverviewPage>
    )
}

export default TakePhotoOverviewEmpty
