/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'

import GatewayDetectedPage from '@src/pages/GatewayDetected'
import InstallGateway from '@src/pages/InstallGateway'
import TakePhotoPage from '@src/pages/TakePhoto'
import SummaryPage from '@src/pages/Summary'
import TakePhotoOverview, {
    TakePhotoOverviewEmpty,
} from '@src/pages/TakePhotoOverview'
import LocationConfirmation from '@src/pages/LocationConfirmation/LocationConfirmationContainer'
import ManualMacAddress from '@src/pages/ManualMacAddress'
import useFlowRouter from '@src/shared-components/FlowRouter/useFlowRouter'
import APP_FLOW_STEPS from '@src/constants/FlowSteps'
import ScanQRErrorPage from '@src/pages/ScanQRError'

import ScanQRPage from '../../pages/ScanQR/ScanQRPage'

const stepsDefintion = {
    [APP_FLOW_STEPS.scanQR]: <ScanQRPage />,
    [APP_FLOW_STEPS.gatewayDetected]: <GatewayDetectedPage />,
    [APP_FLOW_STEPS.installGateway]: <InstallGateway />,
    [APP_FLOW_STEPS.takePhoto]: <TakePhotoPage />,
    [APP_FLOW_STEPS.summaryPage]: <SummaryPage />,
    [APP_FLOW_STEPS.takePhotoOverviewEmpty]: <TakePhotoOverviewEmpty />,
    [APP_FLOW_STEPS.takePhotoOverview]: <TakePhotoOverview />,
    [APP_FLOW_STEPS.locationConfirm]: <LocationConfirmation />,
    [APP_FLOW_STEPS.manual_Mac_Address]: <ManualMacAddress />,
    [APP_FLOW_STEPS.scanQRError]: <ScanQRErrorPage />,
}

function FlowRouter() {
    const {
        currentStep,
    } = useFlowRouter()

    return (
        <>
            {stepsDefintion[currentStep]}
        </>
    )
}

export default FlowRouter
